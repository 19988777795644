<template>
  <div>
    <modal-edit-default-opening-hours
      v-if="showEditModal"
      :showModal="showEditModal"
      :onClickCancel="
        () => {
          showHideEditModal(false)
        }
      "
      :selectedMeetingtypeId="selectedMeetingtypeId"
    />

    <ui-section-header
      :showEdit="true"
      :editFunction="
        () => {
          showHideEditModal(true)
        }
      "
    >
      <template v-slot:title>Edit default time</template>
    </ui-section-header>
    <table class="table is-striped is-fullwidth">
      <thead>
        <tr>
          <th>Day</th>
          <th></th>
          <th>Time</th>
          <th>Open</th>
        </tr>
      </thead>
      <transition name="fade" mode="out-in">
        <tbody v-if="isLoading" key="loading">
          <tr v-for="i in 7" :key="'item_' + i">
            <td>
              <text-placeholder
                class="is-inline-flex"
                :style="placeHolderStyle"
                :isLoading="true"
                :hasSpacing="false"
                :fixSize="120"
                :numberOfItems="1"
              />
            </td>
            <td></td>
            <td>
              <text-placeholder
                class="is-inline-flex "
                :style="placeHolderStyle"
                :isLoading="true"
                :hasSpacing="false"
                :fixSize="60"
                :numberOfItems="1"
              />
              <span
                class="span-minus is-inline-flex has-padding-left has-padding-right"
                >-</span
              >
              <text-placeholder
                class="is-inline-flex"
                :style="placeHolderStyle"
                :isLoading="true"
                :hasSpacing="false"
                :fixSize="60"
                :numberOfItems="1"
              />
            </td>
            <td>
              <text-placeholder
                class="is-inline-flex"
                :style="placeHolderStyle"
                :isLoading="true"
                :hasSpacing="false"
                :fixSize="80"
                :numberOfItems="1"
              />
            </td>
          </tr>
        </tbody>
        <tbody v-else key="ready">
          <tr
            v-for="(openingHour, index) in defaultOpeningHours"
            :key="'do_' + index"
          >
            <td>{{ openingHour.Day | getDayName }}</td>
            <td></td>
            <td>
              {{ openingHour.OpenMinutes | minutesToTime }}
              <span class="has-padding-left has-padding-right">-</span>
              {{ openingHour.CloseMinutes | minutesToTime }}
            </td>
            <td>{{ openingHour.Open ? 'Is open' : 'Is closed' }}</td>
          </tr>
        </tbody>
      </transition>
    </table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import openingsHoursProvider from '@/providers/openinghours'

const ModalEditDefaultOpeningHours = () =>
  import('@/components/Locations/OpeningHours/ModalEditDefaultOpeningHours')
const TextPlaceholder = () => import('@/components/UI/TextPlaceholder')

export default {
  name: 'default-opening-hours',

  components: {
    'modal-edit-default-opening-hours': ModalEditDefaultOpeningHours,
    'text-placeholder': TextPlaceholder,
  },

  props: {
    selectedMeetingtypeId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      isLoading: false,
      showEditModal: false,
      placeHolderStyle: { marginTop: '0px' },
    }
  },

  computed: {
    ...mapState('locationStore', ['location', 'defaultOpeningHours']),
  },

  created() {
    this.getOpeningHoursDay()
  },

  beforeDestroy() {
    this.setDefaultOpeningHours([])
  },

  methods: {
    ...mapMutations('locationStore', ['setDefaultOpeningHours']),

    /**
     * Get opening hours day settings
     */
    getOpeningHoursDay() {
      let self = this
      if (!this.isLoading) {
        this.isLoading = true

        openingsHoursProvider.methods
          .getOpeningHoursDay(this.location.Id, this.selectedMeetingtypeId)
          .then((response) => {
            if (response.status === 200) {
              self.setDefaultOpeningHours(response.data)
            }
          })
          .catch((e) => {})
          .finally(() => {
            self.isLoading = false
          })
      }
    },

    /**
     * Show/Hide edit modal
     */
    showHideEditModal(val = false) {
      this.showEditModal = val
    },
  },
}
</script>

<style lang="scss" scoped>
table {
  td {
    width: 25%;
    vertical-align: middle;
    .span-minus {
      line-height: 1;
    }
  }
}
</style>
